@import "../../partials/functions";
@import "../../partials/variables";
@import "../../partials/mixins";

$panelHeight: 700px;
$totalPanels: 6;
$panelCoverSize: cover;
$iphoneWidth: 300px;
$iphoneHeight: 600px;
$placeholderText: #AAAAAA;


.panel-component {
  height: $panelHeight;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholderText;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholderText;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholderText;
  }

  @for $i from 1 through $totalPanels {
    &.panel-#{$i} {
      background-image: url(//static.ebates.com/static/images/mobile/BG-#{$i}.jpg);
      background-size: $panelCoverSize;
    }
  }

  .panel-call-out {
    width: 380px;
    float: left;

    .phone-screen-image {
      margin: 15px 19px;
      z-index: 0;
      position: absolute;
    }
  }

  .panel-text {
    width: 713px;
    float: right;
  }

  .iphone-background {
    background-image: url(//static.ebates.com/static/images/lyft/device_hardware.png);
    background-size: $panelCoverSize;
    width: $iphoneWidth;
    height: $iphoneHeight;
    position: relative;
    z-index: 10;
  }

  .mobile-number {
    width: 337px;
  }

  .mobile-downloads {
    border-radius: 7px;
    overflow: hidden;

    &.gplay-download {
      width: 139px;
      height: 40px;
    }

    &.apple-download {
      width: 124px;
      height: 40px;
    }

    img {
      position: relative;
      top: -1px;
      left: -1px;
    }
  }

}

.dt {
  display: table
}

.vca {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: $panelHeight;
}

.new-ui .text-me-footer .text-me-footer-left {
  font-size: 24px;
}

.text-me-footer {
  min-width: 1140px;
  width: 100%;
  height: 106px;
  position: fixed;
  bottom: 0;
  background-color: $white-color;
  z-index: 99;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15);
  display: none;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholderText;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholderText;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholderText;
  }

  .button.text-link {
    width: 196px;
    padding: 0 20px;
    border-radius: 0 3px 3px 0;
  }

  .phone-number {
    border-right: none;
    border-radius: 3px 0 0 3px;
  }

}

.footer-box {
  padding: 30px 0;
  margin: auto;
  width: 1180px;
  height: 46px;

  .inline {
    display: inline;
  }
}

input {
  &.phone-number {
    height: 46px;
    width: 250px;
    border: 1px solid $darker-border-color;
    border-radius: 3px;
    font-size: 18px;
    padding: 0 20px;
    line-height: 46px;
    box-sizing: border-box;

    &::placeholder {
      color: #aaa;
      font-size: 16px;
    }
  }
}

.button {
  &.text-link {
    height: 46px;
    width: 300px;
    background-color: $brand-01;
    color: $white-color;
    font-size: 18px;
    line-height: 46px;
    border: none;
    border-radius: 3px;
  }
}

.with-tooltip {
  .tooltip {
    color: $prim-text-color;
    text-align: left;
    white-space: pre-wrap;

    &.tooltip-bottom {
      transform: translate(-50%, 0);
    }

    &.tooltip-right {
      transform: translate(0, -50%);
    }
  }

  &.footer {
    float: left;
  }
}

.tool-tip {
  position: absolute;
  border-radius: 5px;
  background: white;
  border: 1px solid $border-color;
  font-size: 14px;
  color: $prim-text-color;
  padding: 21px;
  line-height: 18px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22);
  width: 100%;
  box-sizing: border-box;
  z-index: 10;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 8px solid black;
    border-color: transparent transparent $white-color $white-color;
    transform: rotate(-45deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.22);
  }

  &.top {
    left: 0;
    top: -100%;

    &:after {
      margin-left: -8px;
      bottom: -8px;
      left: 50%;
    }
  }

  &.left {
    left: -330px;
    top: -18px;

    &:after {
      top: 50%;
      left: initial;
      margin-left: 0px;
      margin-top: -6px;
      bottom: initial;
      right: -8px;
      transform: rotate(-135deg);

    }
  }
}


.footer-box {
  .tool-tip {
    position: absolute;
    border-radius: 5px;
    background: white;
    border: 1px solid $border-color;
    font-size: 14px;
    color: $prim-text-color;
    padding: 21px;
    line-height: 18px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22);
    width: 310px;
    box-sizing: border-box;
    z-index: 10;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border: 8px solid black;
      border-color: transparent transparent $white-color $white-color;
      transform: rotate(-45deg);
      box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.22);
    }
  }
}

.panel-component.panel-5.section {
  background: linear-gradient(134.72deg, #A30593 0%, #ED5050 100%) !important;
  background-size: cover;
}

.mobile-number-text-blk{
  width: 337px;
}
.qr-code{
  width: 200px;
}
